.servicegrid .servicegridimg img {
    width: 100%;
    transition: all 0.5s ease;
    height: 320px;
    object-fit: cover;
}
.serviceshome {
    /* background-color: #f9fafd; */
    padding: 50px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.serviceheaidng {
    text-align: center;
    margin: 0 0 30px;
}
.serviceheaidng h2 {
    font-weight: 800;
}
.serviceshome .servicegrid {
    background-color: var(--color3);
    border-radius: 5px;
    overflow: hidden;
    margin: 0 0 25px;
    transition: all 0.5s ease;
    box-shadow: 0 0 3px #3c485826;
}
.serviceshome .servicegrid .servicegridtitle {
    padding: 20px 10px;
}
.serviceshome .servicegrid .servicegridtitle h2 {
    font-size: 18px;
    text-align: center;
    margin: 0;
    color: var(--color2);
    transition: all 0.5s ease;
}
.serviceshome .servicegrid:hover .servicegridtitle h2 {
    color: var(--color1);
}
.servicegrid .servicegridimg {
    overflow: hidden;
}
.serviceshome .servicegrid:hover .servicegridimg img {
    transform: rotate(2deg) scale(1.15);
}
.serviceshome .servicegrid:hover {
    box-shadow: 0 10px 25px #3c485826 !important;
    transform: translateY(-5px);
}

@media (max-width: 800px) {
    
}