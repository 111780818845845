@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Roboto&display=swap");
html {
  scroll-padding-top: 59px;
  /* height of sticky header */
}
:root {
  --color1: #f00000;
  --color2: #000000;
  --color3: #ffffff;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 800;
}
.display-1 {
  font-size: 80px !important;
}

.display-2 {
  font-size: 72px !important;
}

.display-3 {
  font-size: 64px !important;
}

.display-4 {
  font-size: 56px !important;
}

.bg-primary {
  background-color: #e54b4b !important;
}

.text-primary {
  color: #e54b4b !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #dd2020 !important;
}

.bg-secondary {
  background-color: #5a6d90 !important;
}

.text-secondary {
  color: #5a6d90 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #465571 !important;
}

.bg-success {
  background-color: #25b865 !important;
}

.text-success {
  color: #25b865 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1c8e4e !important;
}

.bg-warning {
  background-color: #f9a836 !important;
}

.text-warning {
  color: #f9a836 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #f59207 !important;
}

.bg-info {
  background-color: #44bed0 !important;
}

.text-info {
  color: #44bed0 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #2da3b4 !important;
}

.bg-danger {
  background-color: #d6374a !important;
}

.text-danger {
  color: #d6374a !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b52536 !important;
}

.bg-dark {
  background-color: #3c4858 !important;
}

.text-dark {
  color: #3c4858 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #272f3a !important;
}

.bg-muted {
  background-color: #8492a6 !important;
}

.text-muted {
  color: #8492a6 !important;
}

a.text-muted:hover, a.text-muted:focus {
  color: #68788f !important;
}

.bg-light {
  background-color: #f9fafd !important;
}

.text-light {
  color: #f9fafd !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d3daf0 !important;
}

.bg-blue {
  background-color: #2d84fb !important;
}

.text-blue {
  color: #2d84fb !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #0568f0 !important;
}

.bg-footer {
  background-color: #465467 !important;
}

.text-footer {
  color: #465467 !important;
}

a.text-footer:hover, a.text-footer:focus {
  color: #323c49 !important;
}

.bg-purple {
  background-color: #662e9b !important;
}

.text-purple {
  color: #662e9b !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #4c2274 !important;
}

.bg-light-green {
  background-color: #67c56e !important;
}

.text-light-green {
  color: #67c56e !important;
}

a.text-light-green:hover, a.text-light-green:focus {
  color: #45b44d !important;
}

.bg-yellow {
  background-color: #f89d36 !important;
}

.text-yellow {
  color: #f89d36 !important;
}

a.text-yellow:hover, a.text-yellow:focus {
  color: #f38508 !important;
}

.bg-light-yellow {
  background-color: #eaba6b !important;
}

.text-light-yellow {
  color: #eaba6b !important;
}

a.text-light-yellow:hover, a.text-light-yellow:focus {
  color: #e4a53e !important;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1140px !important;
  }
}

.row > * {
  position: relative;
}

.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 10px;
}

.rounded {
  border-radius: 7px !important;
}

.rounded-top {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.rounded-left {
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.rounded-right {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.rounded-pill {
  border-radius: 30px !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-top {
  border-top: 1px solid #e9ecef !important;
}

.border-bottom {
  border-bottom: 1px solid #e9ecef !important;
}

.border-left {
  border-left: 1px solid #e9ecef !important;
}

.border-right {
  border-right: 1px solid #e9ecef !important;
}

.modal-open {
  padding-right: 0 !important;
}

.small, small {
  font-size: 85%;
  font-weight: 500;
}

.shadow {
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

@media (max-width: 768px) {
  .display-1 {
    font-size: 56px !important;
  }
  .display-2 {
    font-size: 50px !important;
  }
  .display-3 {
    font-size: 42px !important;
  }
  .display-4 {
    font-size: 36px !important;
  }
}

.btn-primary {
  background-color: #e54b4b !important;
  border: 1px solid #e54b4b !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(229, 75, 75, 0.3);
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary.focus {
  background-color: #dd2020 !important;
  border-color: #dd2020 !important;
  color: #ffffff !important;
}

.btn-outline-primary {
  border: 1px solid #e54b4b;
  color: #e54b4b;
  background-color: transparent;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.focus, .btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #e54b4b;
  border-color: #e54b4b;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(229, 75, 75, 0.3);
}

.btn-secondary {
  background-color: #5a6d90 !important;
  border: 1px solid #5a6d90 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .btn-secondary.focus {
  background-color: #465571 !important;
  border-color: #465571 !important;
  color: #ffffff !important;
}

.btn-outline-secondary {
  border: 1px solid #5a6d90;
  color: #5a6d90;
  background-color: transparent;
}

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.focus, .btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #5a6d90;
  border-color: #5a6d90;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-success {
  background-color: #25b865 !important;
  border: 1px solid #25b865 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(37, 184, 101, 0.3);
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.focus {
  background-color: #1c8e4e !important;
  border-color: #1c8e4e !important;
  color: #ffffff !important;
}

.btn-outline-success {
  border: 1px solid #25b865;
  color: #25b865;
  background-color: transparent;
}

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.focus, .btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #25b865;
  border-color: #25b865;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(37, 184, 101, 0.3);
}

.btn-warning {
  background-color: #f9a836 !important;
  border: 1px solid #f9a836 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(249, 168, 54, 0.3);
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.focus {
  background-color: #f59207 !important;
  border-color: #f59207 !important;
  color: #ffffff !important;
}

.btn-outline-warning {
  border: 1px solid #f9a836;
  color: #f9a836;
  background-color: transparent;
}

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.focus, .btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #f9a836;
  border-color: #f9a836;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(249, 168, 54, 0.3);
}

.btn-info {
  background-color: #44bed0 !important;
  border: 1px solid #44bed0 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(68, 190, 208, 0.3);
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.focus {
  background-color: #2da3b4 !important;
  border-color: #2da3b4 !important;
  color: #ffffff !important;
}

.btn-outline-info {
  border: 1px solid #44bed0;
  color: #44bed0;
  background-color: transparent;
}

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.focus, .btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #44bed0;
  border-color: #44bed0;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(68, 190, 208, 0.3);
}

.btn-danger {
  background-color: #d6374a !important;
  border: 1px solid #d6374a !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(214, 55, 74, 0.3);
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger.focus {
  background-color: #b52536 !important;
  border-color: #b52536 !important;
  color: #ffffff !important;
}

.btn-outline-danger {
  border: 1px solid #d6374a;
  color: #d6374a;
  background-color: transparent;
}

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.focus, .btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #d6374a;
  border-color: #d6374a;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(214, 55, 74, 0.3);
}

.btn-dark {
  background-color: #3c4858 !important;
  border: 1px solid #3c4858 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark.focus {
  background-color: #272f3a !important;
  border-color: #272f3a !important;
  color: #ffffff !important;
}

.btn-outline-dark {
  border: 1px solid #3c4858;
  color: #3c4858;
  background-color: transparent;
}

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.focus, .btn-outline-dark:not(:disabled):not(.disabled):active {
  background-color: #3c4858;
  border-color: #3c4858;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-muted {
  background-color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-muted:hover, .btn-muted:focus, .btn-muted:active, .btn-muted.active, .btn-muted.focus {
  background-color: #68788f !important;
  border-color: #68788f !important;
  color: #ffffff !important;
}

.btn-outline-muted {
  border: 1px solid #8492a6;
  color: #8492a6;
  background-color: transparent;
}

.btn-outline-muted:hover, .btn-outline-muted:focus, .btn-outline-muted:active, .btn-outline-muted.active, .btn-outline-muted.focus, .btn-outline-muted:not(:disabled):not(.disabled):active {
  background-color: #8492a6;
  border-color: #8492a6;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-light {
  background-color: #f9fafd !important;
  border: 1px solid #f9fafd !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(249, 250, 253, 0.3);
}

.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active, .btn-light.focus {
  background-color: #d3daf0 !important;
  border-color: #d3daf0 !important;
  color: #ffffff !important;
}

.btn-outline-light {
  border: 1px solid #f9fafd;
  color: #f9fafd;
  background-color: transparent;
}

.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.focus, .btn-outline-light:not(:disabled):not(.disabled):active {
  background-color: #f9fafd;
  border-color: #f9fafd;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(249, 250, 253, 0.3);
}

.btn-blue {
  background-color: #2d84fb !important;
  border: 1px solid #2d84fb !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(45, 132, 251, 0.3);
}

.btn-blue:hover, .btn-blue:focus, .btn-blue:active, .btn-blue.active, .btn-blue.focus {
  background-color: #0568f0 !important;
  border-color: #0568f0 !important;
  color: #ffffff !important;
}

.btn-outline-blue {
  border: 1px solid #2d84fb;
  color: #2d84fb;
  background-color: transparent;
}

.btn-outline-blue:hover, .btn-outline-blue:focus, .btn-outline-blue:active, .btn-outline-blue.active, .btn-outline-blue.focus, .btn-outline-blue:not(:disabled):not(.disabled):active {
  background-color: #2d84fb;
  border-color: #2d84fb;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(45, 132, 251, 0.3);
}

.btn-footer {
  background-color: #465467 !important;
  border: 1px solid #465467 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(70, 84, 103, 0.3);
}

.btn-footer:hover, .btn-footer:focus, .btn-footer:active, .btn-footer.active, .btn-footer.focus {
  background-color: #323c49 !important;
  border-color: #323c49 !important;
  color: #ffffff !important;
}

.btn-outline-footer {
  border: 1px solid #465467;
  color: #465467;
  background-color: transparent;
}

.btn-outline-footer:hover, .btn-outline-footer:focus, .btn-outline-footer:active, .btn-outline-footer.active, .btn-outline-footer.focus, .btn-outline-footer:not(:disabled):not(.disabled):active {
  background-color: #465467;
  border-color: #465467;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(70, 84, 103, 0.3);
}

.btn-purple {
  background-color: #662e9b !important;
  border: 1px solid #662e9b !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(102, 46, 155, 0.3);
}

.btn-purple:hover, .btn-purple:focus, .btn-purple:active, .btn-purple.active, .btn-purple.focus {
  background-color: #4c2274 !important;
  border-color: #4c2274 !important;
  color: #ffffff !important;
}

.btn-outline-purple {
  border: 1px solid #662e9b;
  color: #662e9b;
  background-color: transparent;
}

.btn-outline-purple:hover, .btn-outline-purple:focus, .btn-outline-purple:active, .btn-outline-purple.active, .btn-outline-purple.focus, .btn-outline-purple:not(:disabled):not(.disabled):active {
  background-color: #662e9b;
  border-color: #662e9b;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(102, 46, 155, 0.3);
}

.btn-light-green {
  background-color: #67c56e !important;
  border: 1px solid #67c56e !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(103, 197, 110, 0.3);
}

.btn-light-green:hover, .btn-light-green:focus, .btn-light-green:active, .btn-light-green.active, .btn-light-green.focus {
  background-color: #45b44d !important;
  border-color: #45b44d !important;
  color: #ffffff !important;
}

.btn-outline-light-green {
  border: 1px solid #67c56e;
  color: #67c56e;
  background-color: transparent;
}

.btn-outline-light-green:hover, .btn-outline-light-green:focus, .btn-outline-light-green:active, .btn-outline-light-green.active, .btn-outline-light-green.focus, .btn-outline-light-green:not(:disabled):not(.disabled):active {
  background-color: #67c56e;
  border-color: #67c56e;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(103, 197, 110, 0.3);
}

.btn-yellow {
  background-color: #f89d36 !important;
  border: 1px solid #f89d36 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 157, 54, 0.3);
}

.btn-yellow:hover, .btn-yellow:focus, .btn-yellow:active, .btn-yellow.active, .btn-yellow.focus {
  background-color: #f38508 !important;
  border-color: #f38508 !important;
  color: #ffffff !important;
}

.btn-outline-yellow {
  border: 1px solid #f89d36;
  color: #f89d36;
  background-color: transparent;
}

.btn-outline-yellow:hover, .btn-outline-yellow:focus, .btn-outline-yellow:active, .btn-outline-yellow.active, .btn-outline-yellow.focus, .btn-outline-yellow:not(:disabled):not(.disabled):active {
  background-color: #f89d36;
  border-color: #f89d36;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 157, 54, 0.3);
}

.btn-light-yellow {
  background-color: #eaba6b !important;
  border: 1px solid #eaba6b !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(234, 186, 107, 0.3);
}

.btn-light-yellow:hover, .btn-light-yellow:focus, .btn-light-yellow:active, .btn-light-yellow.active, .btn-light-yellow.focus {
  background-color: #e4a53e !important;
  border-color: #e4a53e !important;
  color: #ffffff !important;
}

.btn-outline-light-yellow {
  border: 1px solid #eaba6b;
  color: #eaba6b;
  background-color: transparent;
}

.btn-outline-light-yellow:hover, .btn-outline-light-yellow:focus, .btn-outline-light-yellow:active, .btn-outline-light-yellow.active, .btn-outline-light-yellow.focus, .btn-outline-light-yellow:not(:disabled):not(.disabled):active {
  background-color: #eaba6b;
  border-color: #eaba6b;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(234, 186, 107, 0.3);
}

.btn {
  padding: 8px 20px;
  outline: none;
  text-decoration: none;
  font-size: 15px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  border-radius: 7px;
}

.btn:focus {
  box-shadow: none !important;
}

.btn.btn-sm {
  padding: 7px 16px;
  font-size: 10px;
}

.btn.btn-lg {
  padding: 14px 30px;
  font-size: 16px;
}

.btn.searchbtn {
  padding: 6px 20px;
}

.btn.btn-pills {
  border-radius: 30px;
}

.btn.btn-light {
  color: #3c4858 !important;
  background: #f9fafd !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-light:hover, .btn.btn-light:focus, .btn.btn-light:active, .btn.btn-light.active, .btn.btn-light.focus {
  background-color: #f1f4fa !important;
  color: #3c4858 !important;
}

.btn.btn-outline-light {
  border: 1px solid #dee2e6 !important;
  color: #3c4858 !important;
  background-color: transparent;
}

.btn.btn-outline-light:hover, .btn.btn-outline-light:focus, .btn.btn-outline-light:active, .btn.btn-outline-light.active, .btn.btn-outline-light.focus {
  background-color: #f9fafd !important;
  color: #3c4858 !important;
}

.btn.btn-icon {
  display: inline-block;
  height: 35px;
  text-align: center;
  width: 35px;
  line-height: 32px;
  padding: 0;
}

button:not(:disabled) {
  outline: none;
  border: 0;
}

.avatar.avatar-small {
  height: 64px;
  width: 64px;
}

.avatar.avatar-md {
  height: 192px;
  width: 192px;
}

.avatar.avatar-lg {
  height: 256px;
  width: 256px;
}

.progress-box .progress {
  height: 8px;
  overflow: visible;
}

.progress-box .progress .progress-bar {
  animation: animate-positive 3s !important;
  overflow: visible !important;
  border-radius: 5px;
}

.progress-box .progress .progress-value {
  position: absolute;
  top: -30px;
  right: -15px;
  font-size: 13px;
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.nav-pills .nav-link {
  color: #161c2d !important;
  margin: auto 15px;
  border: 1px solid #e9ecef;
}

.nav-pills .nav-link.active {
  background-color: #e54b4b;
  color: #ffffff !important;
  border-color: #e54b4b !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.nav-pills a .skill-container .title {
  font-size: 14px !important;
}

.page-next-level .title {
  font-size: 28px;
  letter-spacing: 1px;
}

.page-next-level .page-next {
  letter-spacing: 0.5px;
}

.page-next-level .page-next li {
  display: inline-block;
  margin-right: 5px;
}

.page-next-level .page-next li:after {
  content: "\F0142";
  font-size: 16px;
  color: #3c4858;
  font-family: 'Material Design Icons';
}

.page-next-level .page-next li:last-child {
  margin-right: 0;
}

.page-next-level .page-next li:last-child:after {
  display: none;
}

.page-next-level .page-next li a, .page-next-level .page-next li span {
  padding-right: 8px;
  transition: all 0.2s ease;
  font-weight: 500;
}

.page-next-level .page-next li a:hover {
  color: #e54b4b !important;
}

.page-next-level .page-next li span:last-child {
  padding-right: 0px;
}

.pagination {
  line-height: 20px;
  font-size: 13px;
}

.pagination li {
  display: inline;
}

.pagination li:first-child a {
  border-left-width: 1px;
  border-radius: 4px 0 0 4px;
}

.pagination li:last-child a {
  border-radius: 0 4px 4px 0;
}

.pagination li a {
  float: left;
  text-decoration: none;
  color: #3c4858;
  border: 1px solid #dee2e6;
  border-left-width: 0;
  background: #ffffff;
}

.pagination li a:hover {
  color: #ffffff;
  background: #e54b4b;
  border-color: #e54b4b;
}

.pagination .active a {
  color: #ffffff;
  background: #e54b4b !important;
  border-color: #e54b4b;
  cursor: not-allowed;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #f9fafd, #f9fafd);
  z-index: 9999999;
  transition: all 0.5s ease;
  visibility: visible;
  opacity: 1;
}

#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#preloader #status .spinner {
  margin: 30px auto 0;
  width: 70px;
  text-align: center;
}

#preloader #status .spinner > div {
  width: 18px;
  height: 18px;
  background-color: #e54b4b;
  border-radius: 5px;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

#preloader #status .spinner .bounce1 {
  animation-delay: -0.32s;
}

#preloader #status .spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.social-icon li a {
  color: #adb5bd;
  border: 1px solid #adb5bd;
  display: inline-block;
  height: 32px;
  text-align: center;
  font-size: 15px;
  width: 32px;
  line-height: 30px;
  transition: all 0.4s ease;
  border-radius: 7px;
}

.social-icon li a:hover {
  background-color: #e54b4b;
  border-color: #e54b4b !important;
  color: #ffffff !important;
}

.social-icon.social li a {
  color: #161c2d;
  border-color: #161c2d;
}

.back-to-top {
  z-index: 99;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 33px;
  height: 33px;
  line-height: 33px;
  display: none;
  color: #ffffff;
  background-color: #6c757d;
  transition: all 0.2s ease;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.back-to-top i {
  font-size: 16px;
}

.back-to-top:hover {
  background-color: #e54b4b;
  color: #ffffff;
}

.blockquote {
  border-left: 3px solid #dee2e6;
  font-size: 16px;
}

.fea {
  stroke-width: 1.6;
}

.fea.icon-sm {
  height: 16px;
  width: 16px;
}

.fea.icon-md-sm {
  height: 24px;
  width: 24px;
}

.fea.icon-md {
  height: 32px;
  width: 32px;
}

.fea.icon-lg {
  height: 42px;
  width: 42px;
}

.fea.icon-ex-lg {
  height: 52px;
  width: 52px;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1110px;
}

.sticky-sidebar {
  position: sticky;
  top: 80px;
}

body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden !important;
  font-size: 16px;
  color: #161c2d;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

::selection {
  background: #6c757d;
  color: #ffffff;
}

a {
  text-decoration: none !important;
}

p {
  letter-spacing: 0.03em;
  line-height: 1.8;
}

.bg-overlay {
  background-color: rgba(60, 72, 88, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bg-overlay-white {
  background: #ffffff;
  opacity: 0.65;
}

.bg-overlay-primary {
  background-color: rgba(229, 75, 75, 0.05) !important;
}

.bg-over-image {
  background-color: rgba(35, 37, 47, 0.5) !important;
  background-image: url("../images/overlay.png");
  background-repeat: repeat;
}

.bg-primary-gradient-overlay {
  background: linear-gradient(to bottom, rgba(229, 75, 75, 0) 0%, rgba(229, 75, 75, 0.25) 25%, rgba(229, 75, 75, 0.5) 50%, rgba(229, 75, 75, 0.75) 75%, #e54b4b 100%);
}

.mt-100 {
  margin-top: 100px;
}

.mb-60 {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .mt-60 {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .section {
    padding: 60px 0;
  }
  .title-heading .heading {
    font-size: 40px !important;
  }
  .section-title .title {
    font-size: 24px;
  }
}

.navbar-custom {
  padding: 20px 0;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.2s ease-in-out;
}

.navbar-custom .navbar-toggler {
  color: #3c4858;
  font-size: 30px;
  padding: 0px;
  box-shadow: none !important;
}

.navbar-custom .navbar-brand {
  margin-right: 0 !important;
}

.navbar-custom .navbar-brand .l-dark,
.navbar-custom .navbar-brand .logo-dark-mode {
  display: none;
}

.navbar-custom .navbar-brand .l-light,
.navbar-custom .navbar-brand .logo-light-mode {
  display: inline-block;
}

.navbar-custom .navbar-nav .nav-item .nav-link {
  color: #ffffff;
  font-size: 14px;
  background-color: transparent !important;
  margin: 0 8px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
  transition: all 0.2s;
  font-family: "Montserrat", sans-serif;
  padding: 25px 15px;
}

.navbar-custom .navbar-nav .nav-item .nav-link:hover, .navbar-custom .navbar-nav .nav-item .nav-link.active, .navbar-custom .navbar-nav .nav-item .nav-link:active {
  color: #e54b4b !important;
}

.navbar-custom .navbar-nav .dropdown-toggle::after {
  content: "\F0140";
  font: normal normal normal 24px/1 "Material Design Icons";
  vertical-align: middle;
  border-top: none;
  margin: 0;
  font-size: 15px;
  float: none;
  transform: translateX(-6px) translateY(-1px);
}

.navbar-custom .navbar-nav .dropdown {
  position: relative;
  transition: all 0.2s ease;
}

.navbar-custom .navbar-nav .dropdown .dropdown-menu {
  border-color: transparent;
  border-radius: 0;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.navbar-custom .navbar-nav .dropdown .dropdown-menu:before {
  content: "";
  position: absolute;
  top: 0;
  margin-left: 45px;
  box-sizing: border-box;
  border: 7px solid #3c4858;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -3px 1px 3px 0px rgba(0, 0, 0, 0.06) !important;
}

.navbar-custom .navbar-nav .dropdown .dropdown-item {
  padding: 7px 0;
  transition: all 0.2s ease;
  font-size: 15px;
}

.navbar-custom .navbar-nav .dropdown .dropdown-item:hover, .navbar-custom .navbar-nav .dropdown .dropdown-item:focus {
  background-color: transparent;
  color: #e54b4b;
}

.navbar-custom .navbar-nav .dropdown:hover .dropdown-menu, .navbar-custom .navbar-nav .dropdown:focus .dropdown-menu {
  display: block;
}

.navbar-custom .navbar-nav.navbar-nav-link .nav-item .nav-link {
  color: #3c4858;
}

.navbar-custom .navbar-nav.navbar-nav-link .nav-item .nav-link:hover, .navbar-custom .navbar-nav.navbar-nav-link .nav-item .nav-link.active, .navbar-custom .navbar-nav.navbar-nav-link .nav-item .nav-link:active {
  color: #e54b4b;
}

.navbar-custom .navbar-nav .nav-item:last-child .nav-link {
  margin-right: 0;
  padding-right: 0;
}

.navbar-custom .social-icon {
  margin: 0 0 0 8px;
}

.navbar-custom .social-icon li {
  margin-bottom: 0px !important;
}

.navbar-custom .social-icon li a {
  color: #3c4858;
  border-color: #3c4858;
}

.navbar-custom .social-icon.light-social-icon li a {
  color: #e9ecef;
  border-color: #e9ecef;
}

.nav-sticky.navbar-custom {
  margin-top: 0px;
  padding: 12px 0px;
  background-color: #ffffff;
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  color: #3c4858 !important;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.nav-sticky.navbar-custom .navbar-brand .l-dark {
  display: inline-block;
}

.nav-sticky.navbar-custom .navbar-brand .l-light {
  display: none;
}

.nav-sticky.navbar-custom .navbar-nav {
  margin-top: 0px;
}

.nav-sticky.navbar-custom .navbar-nav .nav-item .nav-link {
  color: #2f3545;
}

.nav-sticky.navbar-custom .navbar-nav .nav-item .nav-link:hover, .nav-sticky.navbar-custom .navbar-nav .nav-item .nav-link.active, .nav-sticky.navbar-custom .navbar-nav .nav-item .nav-link:active {
  color: #e54b4b !important;
}

.nav-sticky.navbar-custom.sticky-light {
  background-color: #ffffff;
}

.nav-sticky .navbar-brand {
  color: #3c4858 !important;
}

.nav-sticky .social-icon.light-social-icon li a {
  color: #3c4858;
  border-color: #3c4858;
}

.nav-sticky .dropdown-menu {
  border: none;
}

.nav-sticky .dropdown-menu::before {
  box-shadow: -3px 1px 3px 0px rgba(0, 0, 0, 0.06) !important;
}

@media (min-width: 992px) {
  .navbar-custom .navbar-nav .dropdown .dropdown-menu {
    margin-top: 4px !important;
  }
}
@media (max-width: 991.98px) {
  .navbar-custom .navbar-brand .l-dark {
    display: inline-block !important;
  }
  .navbar-custom .navbar-brand .l-light {
    display: none !important;
  }
  .navbar-custom .navbar-nav .nav-item .nav-link {
    padding: 5px 0px;
  }
}
@media (max-width: 1023px) {
  .navbar-custom .nav-social {
    display: none;
  }
}

@media (max-width: 991px) {
  .navbar-custom {
    background-color: #ffffff !important;
    padding: 10px 0px !important;
  }
  .navbar-custom .navbar-nav .nav-item .nav-link {
    color: #161c2d !important;
  }
  .navbar-custom .navbar-nav .nav-item .dropdown-toggle:after {
    float: right;
  }
  .navbar-custom .navbar-nav .dropdown .dropdown-menu {
    box-shadow: none !important;
  }
  .navbar-custom .navbar-nav .dropdown .dropdown-menu:before {
    display: none;
  }
  .navbar-custom .navbar-brand .l-dark {
    display: inline-block !important;
  }
  .navbar-custom .navbar-brand .l-light {
    display: none;
  }
  .navbar-custom .social-icon {
    margin-top: 12px !important;
  }
  .navbar-custom .social-icon.light-social-icon li a {
    color: #3c4858;
    border-color: #3c4858;
  }
}

@media only screen and (min-width: 768px) {
  .navbar .nav-item .dropdown-menu-right::before {
    margin-left: 160px !important;
  }
  .navbar .nav-item .dropdown .dropdown-menu:before {
    top: 52px;
  }
}

@media (max-width: 768px) {
  .navbar-nav .nav-item .dropdown-menu-right::before {
    margin-left: 45px !important;
  }
}

@media (max-height: 480px) {
  .navbar-nav {
    max-height: 200px;
    overflow-y: auto;
  }
}

.full-home {
  height: 100vh;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-home {
  padding: 260px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half {
  padding: 170px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.mouse-icon {
  position: absolute;
  left: 0;
  right: 0;
  height: 42px;
  border: 2px solid #495057;
  bottom: 30px;
  margin: auto;
  width: 30px;
  z-index: 2;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.mouse-icon.mouse-icon-white {
  border-color: #f9fafd;
}

.mouse-icon.mouse-icon-white .wheel {
  background: #f9fafd;
}

.mouse-icon .wheel {
  background: #495057;
  height: 8px;
  margin-left: auto;
  margin-right: auto;
  top: 30px;
  width: 2px;
}
.mover {
  animation: mover 1s infinite alternate;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.main-icon {
  border: 2px solid #ced4da;
  height: 40px;
  width: 40px;
  line-height: 0;
  text-align: center;
}
.navbar-custom .navbar-brand img {
  width: 200px;
}
.navbar-collapse {
  justify-content: end;
}
.mainfooter {
  background-color: #414141;
  padding: 50px 0;
}
.footerlogo {
  margin: 0 0 15px;
}
.footercontents p {
  color: var(--color3);
}
.footerheading h2 {
  font-size: 24px;
  color: var(--color3);
}
.footermenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footermenu ul li {
  margin: 0 0 10px;
}
.footermenu ul li a {
  color: var(--color3);
  font-size: 16px;
  transition: all 0.5s ease;
}
.footermenu ul li a:hover, .footercontactinfo ul li a:hover {
  color: var(--color1);
}
.footercontactinfo ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footercontactinfo ul li {
  margin: 0 0 15px;
  color: var(--color3);
}
.footercontactinfo ul li a {
  color: var(--color3);
  font-size: 16px;
  transition: all 0.5s ease;
}
.footercontactinfo ul li svg {
  margin: 0 10px 0 0;
}
.socialmedialist ul li {
  margin: 0 15px 0 0 !important;
}
.footerbottom {
  background-color: #2d2d2d;
  padding: 10px 0;
}
.footerbottom p {
  color: var(--color3);
  margin: 0;
  text-align: center;
}
.footerbottom p a {
  color: var(--color3);
  transition: all 0.5s ease;
}
.footerbottom p a:hover {
  color: var(--color1);
}
.whatsappflot {
  position: fixed;
  right: 20px;
  bottom: 115px;
}
.whatsappiconfix {
  background-color: #25D366;
  height: 60px;
  width: 60px;
  border-radius: 30px;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}
.whatsappiconfix a {
  color: var(--color3);
  font-size: 28px;
  text-align: center;
  display: block;
  line-height: 2;
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}
.footerheading {
  padding: 0 0 15px;
}
.footerheading h2 {
  border-bottom: 1px solid var(--color3);
  padding: 0 0 15px;
  position: relative;
}