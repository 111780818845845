.instagramfeed .instagram-post img {
    width: 100%;
    height: 330px;
    object-fit: cover;
    transition: all 0.5s ease;
}
.instagramfeed {
    padding: 50px 0;
}
.instagramfeed .instagram-post {
    background-color: #fcfcfc;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 0 25px;
    transition: all 0.5s ease;
    box-shadow: 0 0 3px #3c485826;
    padding: 10px;
    position: relative;
}
.instagramfeed .instagram-post .instapostimage {
    overflow: hidden;
    position: relative;
}
.instagramfeed .instagram-post .instapostimage::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 46%);
    content: '';
    opacity: 0;
    transition: all 0.5s ease;
}
.instagramfeed .instagram-post .instafeedurl {
    position: absolute;
    left: 0;
    right: 0;
    top: 43%;
    text-align: center;
}
.instagramfeed .instagram-post .instafeedurl i {
    font-size: 33px;
    color: var(--color3);
    opacity: 0;
    transition: all 0.5s ease;
}
.instagramfeed .instagram-post:hover img {
    transform: rotate(2deg) scale(1.15);
}
.instagramfeed .instagram-post:hover .instapostimage::after, .instagramfeed .instagram-post:hover .instafeedurl i {
    opacity: 1;
}
.viewmoreinstabtn {
    text-align: center;
}
.viewmoreinstabtn a {
    font-size: 17px;
    font-weight: 700;
    color: var(--color1);
    border-bottom: 2px solid var(--color1);
    padding: 0 12px 3px;
    transition: all 0.5s ease;
}
.viewmoreinstabtn a:hover {
    color: var(--color2);
    border-color: var(--color2);
}
@media (max-width: 800px) {
    .instagramfeed .instagram-post img {
        height: 400px;
    }
}
@media (max-width: 767px) {
    .instagramfeed .instagram-post img {
        height: 210px;
    }
    .instagramfeed .instagram-post {
        margin: 0 0 15px;
    }
}