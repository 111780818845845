.aboutussection {
    padding: 100px 0 50px;
}
.aboutussection .aboutusrigtimage img {
    width: 100%;
    height: 700px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px #d0d0d0;
}
.aboutussection .aboutcontentleft h2 {
    font-weight: 800;
}
@media (max-width: 800px) {
    .aboutussection {
        padding: 25px 0 50px;
    }
    .aboutussection .aboutusrigtimage img {
        height: auto;
    }
}