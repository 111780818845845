.portfoliolist ul {
    list-style: none;
    margin: 0;
}
.portsidebarheaing h2 {
    font-size: 23px;
    border-bottom: 1px solid var(--color2);
    padding: 0 0 7px;
    margin: 0 0 15px;
    position: relative;
}
.portfoliolist ul li a {
    display: block;
    color: var(--color2);
    padding: 7px 0;
    font-size: 17px;
    transition: all 0.5s ease;
}
.portfoliolist ul li::before {
    content: '\F19B0';
    display: inline-block;
    font: normal normal normal 24px / 1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    left: -30px;
    top: 2px;
    font-size: 25px;
    color: var(--color2);
    transition: all 0.5s ease;
}
.portfoliolist ul li {
    position: relative;
}
.portfoliolist ul li a:hover, .portfoliolist ul li:hover::before {
    color: var(--color1);
}
.portfoliolist ul li.active a, .portfoliolist ul li.active::before {
    color: var(--color1);
}
.mainportsidebar {
    position: sticky;
    top: 120px;
}
.portfolio {
    padding: 50px 0;
}
.portfoliogridbox img {
    width: 100%;
    height: 220px;
    object-fit: cover;
}
.portfoliogridbox {
    margin: 0 0 25px;
}