.contactustoparea {
    padding: 50px 0;
}
.contactustop {
    text-align: center;
}
.contactustop p {
    width: 70%;
    margin: 10px auto 0;
    display: block;
}
.contactinfoboxs {
    position: relative;
    padding: 0 0 0 60px;
    margin: 0 0 50px;
}
.contacticons {
    position: absolute;
    left: 0;
    top: 5px;
    border: 2px solid var(--color1);
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 2;
}
.contactinfoboxs h3 {
    font-size: 20px;
    margin: 0 0 5px;
}
.contactinfoboxs p {
    margin: 0;
}
.contactinfoboxs p a {
    color: var(--color2);
    transition: all 0.5s ease;
}
.contactinfoboxs p a:hover {
    color: var(--color1);
}
.contactformdta {
    box-shadow: 0 0 3px #3c485826;
    padding: 20px;
    border-radius: 5px;
}
.contactformdta h3 {
    border-bottom: 1px solid #a6a6a6;
    padding: 0 0 10px;
    margin: 0 0 15px;
    position: relative;
}
.contactformdta h3::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: var(--color1);
}
.contactform label {
    margin: 20px 0 4px;
}
.contactform .form-group:first-child label {
    margin-top: 0;
}
.contactform label span.required {
    color: var(--color1);
}
.contactform .form-control {
    border-radius: 3px;
}
.contactform .form-control::placeholder {
    color: #d2d2d2;
}
.contactform button {
    margin: 15px 0 0;
    background-color: var(--color2);
    font-size: 19px;
    padding: 8px 35px;
    color: var(--color3);
    transition: all 0.5s ease;
    border-radius: 5px;
}
.form-group.text-right {
    text-align: right;
}
.contactform button:hover {
    background-color: var(--color1);
}
.contactforms {
    padding: 0 0 50px;
}