.servicecontent {
    padding: 50px 0;
}
.servicedatacont {
    border-left: 1px solid #d4d4d4;
    padding: 0 0 0 30px;
}
.width80 {
    width: 80%;
    margin: auto;
    display: block;
}
.serviceareacontent h1, .servicegallery h2 {
    font-size: 30px;
    font-weight: 800;
    margin: 0 0 15px;
}
.gallerygridbox img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}
.gallerygridbox {
    margin: 0 0 25px;
}
.serviceareacontent p a {
    font-weight: bold;
    color: var(--color2);
    transition: all 0.5s ease;
}
.serviceareacontent p a:hover {
    color: var(--color1);
}