.teams {
    padding: 50px 0;
    text-align: center;
}
.teams .teamsheaidng h2 {
    font-weight: 800;
}
.teamsgrid .teamsimagearea img {
    width: 100%;
    height: 260px;
    object-fit: cover;
    object-position: top;
}
.teamsgrid .teamsinfo h2 {
    font-size: 20px;
    font-weight: 800;
    margin: 0 0 0;
}
.teamsgrid .teamsinfo p {
    margin: 0 0 0;
    /* font-style: italic; */
}
.teamsgrid .teamsinfo {
    padding: 10px 10px;
}
.teamsgrid {
    background-color: #eaeaea;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin: 25px 0 0;
    transition: all 0.5s ease;
    box-shadow: 0 0 3px #3c485826;
}
.teamsgrid:hover {
    box-shadow: 0 10px 25px #0001034a !important;
    transform: translateY(-5px);
}