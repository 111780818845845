.workprocess {
    padding: 50px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.workprocessheading {
    text-align: center;
}
.workprocessheading h2 {
    margin: 0 0 15px;
}
.workprocessheading p {
    width: 63%;
    margin: auto;
    display: block;
}
.workprocessgrid {
    background-color: var(--color3);
    border-radius: 5px;
    padding: 15px;
    margin: 35px 0 0;
    text-align: center;
    min-height: 165px;
}
.workprocessgrid .imageareaprocess img {
    width: 65px;
    height: 65px;
    object-fit: contain;
}
.workprocessgrid .imageareaprocess {
    margin: 0 0 15px;
}
.workprocessgrid .processcontent p {
    margin: 0;
}
.processbuttionarea {
    margin: 40px 0 0;
    text-align: center;
}
.processbuttionarea ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    column-gap: 30px;
}
.processbuttionarea ul a {
    display: block;
    border: 3px solid var(--color1);
    padding: 10px 30px;
    font-size: 18px;
    transition: all 0.5s ease;
    color: var(--color1);
    font-weight: 700;
}
.processbuttionarea ul li:first-child a {
    background-color: var(--color1);
    color: var(--color3);
}
.processbuttionarea ul a:hover {
    background-color: var(--color1);
    color: var(--color3);
}
.processbuttionarea ul li:first-child a:hover {
    background-color: transparent;
    color: var(--color1);
}
@media (max-width: 800px) {
    .workprocessheading p {
        width: 100%;
    }
    .workprocessheading h2 {
        font-size: 23px;
        line-height: 1.5;
    }
}
@media (max-width: 767px) {
    .processbuttionarea ul a {
        font-size: 14px;
    }
}